import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProgressChart } from "./clients/currentWeekProgressBar/currentWeekProgressBar";
import { AbsenceHeader } from "./clients/absence/absenceHeader";
import { AlertHeader } from "./clients/alerts/alertHeader";
import { CaseLoadAlert } from "./clients/types";
import { getOnlyCaseLoadEntriesWithAlertsFromLocalStorage } from "./clients/utils";
import { getCurrentMondayAndFriday } from "../../utils/dateUtils";
import { NavBarOpenStateContext } from "../../layouts/NavBar/NavBarOpenStateContext";
import { Tabs } from "./clients/tabs";
import { HorizontalTab } from "../../components/ui/horizontalTab";

const CaseLoadManager = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { isNavBarOpen } = useContext(NavBarOpenStateContext)!;

  const [alerts, setAlerts] = useState<
    {
      caseLoadEntryId: string;
      alerts: CaseLoadAlert[];
    }[]
  >([]);

  const [absence, setAbsence] = useState<{
    startDate: string;
    endDate: string;
  } | null>(null);

  const [onlyCaseLoadsWithAlert, setOnlyCaseLoadsWithAlert] = useState(
    getOnlyCaseLoadEntriesWithAlertsFromLocalStorage()
  );

  const { monday } = getCurrentMondayAndFriday();

  const getDefaultSelectedDate = () => {
    const fromUrl = searchParams.get("period");
    return fromUrl ? new Date(fromUrl!) : monday;
  };

  const [date, setDate] = useState<Date>(getDefaultSelectedDate());

  const getWrapperClass = () => {
    const isOldDesign = localStorage.getItem("force-old-design") == "true";
    if (!isOldDesign) return "";

    return isNavBarOpen ? "-ml-12" : "ml-0";
  };

  return (
    <main className={`px-6 ${getWrapperClass()}`}>
      <AbsenceHeader absence={absence} />
      <AlertHeader
        hasAbsence={absence != null}
        alerts={alerts}
        onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
        setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
      />

      <div className="mt-24 relative w-full">
        <div className="pt-2">
          <HorizontalTab>
            <Tabs
              setAlerts={setAlerts}
              setAbsence={setAbsence}
              date={date}
              setDate={setDate}
              onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
              setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
            />
          </HorizontalTab>
        </div>

        <div className="z-0 absolute top-0 -mt-[70px] w-full flex justify-end">
          <ProgressChart date={date} />
        </div>
      </div>
    </main>
  );
};

export default CaseLoadManager;
